@font-face {
  font-family: OptimaLTStd;
  src: url("./fonts/OptimaLTStd-Medium.woff") format("woff"), url("./fonts/OptimaLTStd-Medium.ttf") format("TrueType");
}

body {
  margin: 0;
  font-family: OptimaLTStd, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.field-a {
  width: 75px;
  height: 75px;
  font-size: 40px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid $gray;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}